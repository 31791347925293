import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "but-what-if-my-teenager-doesnt-want-to-seek-help"
    }}>{`But what if my teenager doesn’t want to seek help?`}</h1>
    <p>{`There can be many reasons why your teenager may be unwilling to seek help.`}<sup>{`1`}</sup></p>
    <p>{`Your teenager might:`}</p>
    <ul>
      <li parentName="ul">{`Feel embarrassed to talk about what they are going through.`}</li>
      <li parentName="ul">{`Worry that others will think badly about them if they were to tell them.`}</li>
      <li parentName="ul">{`Be concerned about their privacy.`}</li>
      <li parentName="ul">{`Think that nothing and no one can help.`}</li>
      <li parentName="ul">{`Not realise the seriousness of their problems.`}</li>
    </ul>
    <p>{`Find out your teenager’s specific `}<strong parentName="p">{`reasons`}</strong>{` for not wanting to seek professional help and work through these concerns with them. Although your teenager may be reluctant to admit that they are experiencing symptoms, you should trust your instincts and seek professional help if you are concerned.`}</p>
    <p><em parentName="p">{`Getting help and treatment `}<strong parentName="em">{`early`}</strong>{` for your teenager can reduce the severity of depression and clinical anxiety and prevent future occurrences.`}</em></p>
    <p>{`If you think that you may be depressed or have clinical anxiety yourself, seek help from a mental health professional. This will set a good example for your teenager.`}</p>
    <SingleImage smallGridSize={10} gridSize={10} src="/images/m10/49.svg" alt="child imagining" mdxType="SingleImage" />
    <sup>
  1. See <GoTo to="/appendix" mdxType="GoTo">Appendix</GoTo> page for full reference.
    </sup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      